import React, { useState, useEffect } from 'react';
import OpenAI from 'openai';
import { RefreshCw, RotateCcw, Sun, Moon, Send, Info, Copy, Check } from 'lucide-react';


const App = () => {
  const [topic, setTopic] = useState('');
  const [style, setStyle] = useState('');
  const [opener, setOpener] = useState('');
  const [conversation, setConversation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showChangeResponse, setShowChangeResponse] = useState(false);
  const [changeResponseText, setChangeResponseText] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [userReply, setUserReply] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [copiedIndex, setCopiedIndex] = useState(null);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  const copyToClipboard = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 2000); // Reset after 2 seconds
    });
  };

  const generateMessage = async (prompt, messageType, topic, style) => {
    const response = await fetch('https://firstmove-backend.uc.r.appspot.com/generate-message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt, messageType, topic, style }),
    });
  
    if (!response.ok) {
      throw new Error('Failed to generate message');
    }
  
    const data = await response.json();
    return data.message;
  };

  const generateOpener = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const prompt = `Create a conversation opener about ${topic} in a ${style} style.`;
      const generatedOpener = await generateMessage(prompt, 'opener');
      setOpener(generatedOpener);
      setConversation([{ role: 'assistant', content: generatedOpener }]);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserReply = async (reply) => {
    const updatedConversation = [...conversation, { role: 'user', content: reply }];
    setConversation(updatedConversation);
    setIsLoading(true);
    setError(null);

    try {
      const prompt = `The user said: "${reply}". Respond to this message in the context of the ongoing conversation.`;
      const response = await generateMessage(prompt, 'reply');
      setConversation([...updatedConversation, { role: 'assistant', content: response }]);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeResponse = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const lastMessage = conversation[conversation.length - 1];
      const conversationHistory = conversation
        .map(msg => `${msg.role}: ${msg.content}`)
        .join('\n');
      
      const prompt = `
        Conversation history:
        ${conversationHistory}

        The last assistant message was: "${lastMessage.content}"
        
        Modify this last message based on the following feedback: "${changeResponseText}"
        
        Keep the response natural, concise, and appropriate for a dating app conversation.
        Take into account the entire conversation history when making the modification.
      `;

      const modifiedResponse = await generateMessage(prompt, 'modify');
      const updatedConversation = [...conversation];
      updatedConversation[conversation.length - 1] = { ...lastMessage, content: modifiedResponse };
      setConversation(updatedConversation);
      setShowChangeResponse(false);
      setChangeResponseText('');
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setTopic('');
    setStyle('');
    setOpener('');
    setConversation([]);
    setError(null);
    setShowChangeResponse(false);
    setChangeResponseText('');
    setUserReply('');
  };

  const handleSendReply = () => {
    if (userReply.trim() && !isLoading) {
      handleUserReply(userReply);
      setUserReply('');
    }
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'dark' : ''}`}>
      <div className="bg-white dark:bg-gray-900 min-h-screen transition-colors duration-200 ease-in-out">
        <div className="container mx-auto p-4">
          <div className="max-w-md mx-auto bg-gray-100 dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold text-rose-500 dark:text-rose-400">FirstMove</h1>
                <button
                  onClick={() => setIsDarkMode(!isDarkMode)}
                  className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200 ease-in-out"
                >
                  {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
                </button>
              </div>
              <div className="relative">
                <p className="text-gray-600 dark:text-gray-400 text-sm mb-4">
                  FirstMove helps you break the ice on dating apps. Generate 
                  clever openers, engage in witty conversations, and refine 
                  your messages to make a great first impression!
                  <span 
                    className="inline-block ml-1 cursor-pointer"
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    onClick={() => setShowTooltip(!showTooltip)}
                  >
                    <Info size={16} className="text-rose-500 dark:text-rose-400" />
                  </span>
                </p>
                {showTooltip && (
                  <div className="absolute z-10 p-3 bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded shadow-lg max-w-xs">
                    Hey there! 😊 We're cooking up some amazing personalized openers just for you. In the meantime, feel free to sprinkle your own magic on these replies. After all, nobody knows your charm better than you do! 💫
                  </div>
                )}
              </div>
              <input
                className="w-full p-2 mb-2 bg-gray-200 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded text-gray-700 dark:text-gray-300 focus:outline-none focus:border-rose-500 dark:focus:border-rose-400"
                type="text"
                placeholder="Topic (e.g., Live Aid 1985, Harry Potter, Pranayama)"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
              />
              <input
                className="w-full p-2 mb-4 bg-gray-200 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded text-gray-700 dark:text-gray-300 focus:outline-none focus:border-rose-500 dark:focus:border-rose-400"
                type="text"
                placeholder="Style (e.g., Casual, Flirty, Humorous, Cool)"
                value={style}
                onChange={(e) => setStyle(e.target.value)}
              />
              <button
                className="w-full bg-rose-500 hover:bg-rose-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-200 ease-in-out"
                onClick={generateOpener}
                disabled={isLoading}
              >
                {isLoading ? 'Generating...' : 'Generate Opener'}
              </button>
              <button
                  className="w-full bg-gray-300 dark:bg-gray-600 hover:bg-gray-400 dark:hover:bg-gray-500 text-gray-800 dark:text-gray-200 font-bold py-2 px-4 my-2 rounded focus:outline-none focus:shadow-outline transition-colors duration-200 ease-in-out"
                  onClick={handleReset}
                >
                  <RotateCcw className="inline mr-2" size={16} />
                  Reset Conversation
                </button>
              {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            </div>
  
            {conversation.length > 0 && (
              <div className="p-6 border-t border-gray-200 dark:border-gray-700">
                <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">Conversation</h2>
                <div className="h-[400px] overflow-y-auto mb-4 pr-2">
                  <div className="space-y-4">
                    {conversation.map((message, index) => (
                      <div key={index} className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'} items-center group`}>
                        <div className={`max-w-[75%] p-3 rounded-lg ${
                          message.role === 'assistant' 
                            ? 'bg-rose-100 dark:bg-rose-900 text-rose-800 dark:text-rose-200' 
                            : 'bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200'
                        }`}>
                          {message.content}
                        </div>
                        <button
                          onClick={() => copyToClipboard(message.content, index)}
                          className={`ml-2 p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors duration-200 ease-in-out ${
                            message.role === 'user' ? 'order-first mr-2' : ''
                          }`}
                        >
                          {copiedIndex === index ? <Check size={16} /> : <Copy size={16} />}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                {conversation[conversation.length - 1].role === 'assistant' && (
                  <div className="mt-2 mb-4">
                    <button
                      onClick={() => setShowChangeResponse(!showChangeResponse)}
                      className="text-sm text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 focus:outline-none transition-colors duration-200 ease-in-out"
                    >
                      <RefreshCw className="inline-block mr-1" size={16} />
                      Change Response
                    </button>
                  </div>
                )}
                {showChangeResponse && (
                  <div className="mb-4">
                    <textarea
                      className="w-full p-2 mb-2 bg-gray-200 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded text-gray-700 dark:text-gray-300 focus:outline-none focus:border-rose-500 dark:focus:border-rose-400"
                      rows="3"
                      placeholder="Describe how you want to change the response..."
                      value={changeResponseText}
                      onChange={(e) => setChangeResponseText(e.target.value)}
                    ></textarea>
                    <button
                      className="bg-rose-500 hover:bg-rose-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-200 ease-in-out"
                      onClick={handleChangeResponse}
                      disabled={isLoading}
                    >
                      Apply Changes
                    </button>
                  </div>
                )}
                <div className="flex items-center mb-4">
                  <input
                    className="flex-grow p-2 mx-2 bg-gray-200 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-l text-gray-700 dark:text-gray-300 focus:outline-none focus:border-rose-500 dark:focus:border-rose-400"
                    type="text"
                    placeholder="Enter matched reply..."
                    value={userReply}
                    onChange={(e) => setUserReply(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && !isLoading) {
                        handleSendReply();
                      }
                    }}
                    disabled={isLoading}
                  />
                  <button
                    className="p-2 bg-rose-500 hover:bg-rose-600 text-white rounded-r focus:outline-none focus:shadow-outline transition-colors duration-200 ease-in-out"
                    onClick={handleSendReply}
                    disabled={isLoading}
                  >
                    <Send size={20} />
                  </button>
                </div>
              </div>
            )}
            <div className="p-4 border-t border-gray-200 dark:border-gray-700 text-center">
            <p className="text-base text-gray-600 dark:text-gray-400">
              Made by <a href="https://x.com/solopreneur1997" className="text-rose-500 dark:text-rose-400 hover:underline" target="_blank" rel="noopener noreferrer">Batuhan👨🏼‍💻</a>
            </p>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;